<template>
  <b-container fluid>
    <div class="row">
      <div class="col-md-6 mx-auto mt-5">
        <div class="iq-card p-4">
          <div class="content">
            <div><img style="width:100%;" src="../../assets_gide/img/img/thank-you-card.jpg"/></div>
            <h1>{{ title }}</h1>
              <p class="mt-2" v-html="message"></p>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { socialvue } from "../../config/pluginInit"
export default {
  name: "ThankYouPage",
  data: () => ({
    title: "Thank You",
    message: `Thank you for your participation`
  }),
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    socialvue.index()
    let code = this.$route.query.code ? this.$route.query.code : ""

    switch (code) {
      case "GPATHS_THANK_YOU":
        this.title = ""
        this.message = "We genuinely appreciate your time and openness in sharing your thoughts. Your feedback contributes to the ongoing improvement of GPaths, ensuring it remains a valuable resource for students navigating their career journeys. Thank you for being a part of the GIDE community!"
        break

      default:
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.content h1{
  font-weight: 600;
  font-size: 37px;
  text-align: center;
}

p{
  text-align: center;
  color: var(--iq-title-text);
  font-size: 14px;
  font-weight: 500;
}
.container-fluid{
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  height: max-content;
}
@media screen and (min-width: 1200px) {
  .container-fluid{
    padding-left: 230px !important;
  }
}
</style>
